import React, {Component} from 'react';

class Examples extends Component {
    render() {
        return (
            <>
                <h2 className={"text-center"}>Примеры кода</h2>

                <section className={"examples section-b-space"}>
                    <div className={"row"}>
                        <div className={"col-lg-4 col-md-6"}>
                            <a href={"/education/examples/base"}>
                                <div className={"example-block"}>
                                    <div className={"header"}>
                                        <div className={"title"}>
                                            <div className={"name"}>Базовый</div>
                                            <p className={"language"}>Omegabot IDE и C++</p>
                                        </div>
                                        <img alt={"Пример кода"}
                                             src={"/assets/images/MenuPages/Education/examples/base.png"}
                                             className={"img-fluid"}/>
                                    </div>
                                    <div className={"description"}>
                                        5 упражнений (сценариев поведения) с Базовым набором Омегабот
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className={"col-lg-4 col-md-6"}>
                            <a href={"/education/examples/advanced"}>
                                <div className={"example-block"}>
                                    <div className={"header"}>
                                        <div className={"title"}>
                                            <div className={"name"}>Расширенный</div>
                                            <p className={"language"}>Omegabot IDE и C++</p>
                                        </div>
                                        <img alt={"Пример кода"}
                                             src={"/assets/images/MenuPages/Education/examples/advanced.png"}
                                             className={"img-fluid"}/>
                                    </div>
                                    <div className={"description"}>
                                        5 упражнений (сценариев поведения) с Расширенным
                                        набором Омегабот
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Examples