import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P1Definitions extends Component {
    render() {
        return (
            <AccordionItem title={<li>Определение терминов</li>}>
                <div>
                    В настоящей Политике конфиденциальности используются следующие термины:

                    <ol>
                        <li><strong>Сайт</strong> – совокупность всей информации (текстовой и графической),
                            находящейся в сети интернет по адресу <a href={"/"}>https://omegabot.ru</a>, принадлежащая
                            ООО «Омега».</li>

                        <li><strong>Оператор персональных данных (оператор)</strong> – ООО «Омега» (ИНН 7826108963,
                            ОГРН 1027810320240), самостоятельно или совместно с другими лицами
                            организующее и (или) осуществляющее обработку персональных данных, а
                            также определяющие цели обработки персональных данных, состав персональных
                            данных, подлежащих обработке, действия (операции), совершаемые с персональными
                            данными.</li>

                        <li><strong>Администрация Сайта</strong> – сотрудники ООО «Омега», уполномоченные на изменение
                            информации на Сайте.</li>

                        <li><strong>Пользователь Сайта (далее – Пользователь)</strong> – посетитель или зарегистрированный
                            Пользователь Сайта ООО «Омега».</li>

                        <li><strong>Стороны</strong> – ООО «Омега» и Пользователь Сайта, заключившие Настоящее Соглашение.</li>

                        <li><strong>Персональные данные</strong> – любая информация, относящаяся к прямо или косвенно
                            определенному, или определяемому физическому лицу (субъекту персональных
                            данных).</li>

                        <li><strong>Обработка персональных данных</strong> – любое действие (операция) или совокупность
                            действий (операций), совершаемых с использованием средств автоматизации или
                            без использования таких средств с персональными данными, включая сбор,
                            запись, систематизацию, накопление, хранение, уточнение (обновление,
                            изменение), извлечение, использование, передачу (распространение, предоставление,
                            доступ), обезличивание, блокирование, удаление, уничтожение персональных
                            данных.</li>

                        <li><strong>Автоматизированная обработка персональных данных</strong> - обработка персональных
                            данных с помощью средств вычислительной техники.</li>

                        <li><strong>Распространение персональных данных</strong> - действия, направленные на раскрытие
                            персональных данных неопределенному кругу лиц.</li>

                        <li><strong>Предоставление персональных данных</strong> - действия, направленные на раскрытие
                            персональных данных определенному лицу или определенному кругу лиц.</li>

                        <li><strong>Блокирование персональных данных</strong> - временное прекращение обработки
                            персональных данных (за исключением случаев, если обработка необходима
                            для уточнения персональных данных).</li>

                        <li><strong>Уничтожение персональных данных</strong> - действия, в результате которых
                            становится невозможным восстановить содержание персональных данных
                            в информационной системе персональных данных и (или) в результате которых
                            уничтожаются материальные носители персональных данных.</li>

                        <li><strong>Обезличивание персональных данных</strong> - действия, в результате которых
                            становится невозможным без использования дополнительной информации
                            определить принадлежность персональных данных конкретному субъекту
                            персональных данных.</li>

                        <li><strong>Информационная система персональных данных</strong> - совокупность содержащихся
                            в базах данных персональных данных и обеспечивающих их обработку
                            информационных технологий и технических средств.</li>

                        <li><strong>Трансграничная передача персональных данных</strong> - передача персональных
                            данных на территорию иностранного государства органу власти иностранного
                            государства, иностранному физическому лицу или иностранному юридическому
                            лицу.</li>

                        <li><strong>Конфиденциальность персональных данных</strong> – обязательное для соблюдения
                            Оператором, Пользователем или иным получившим доступ к персональным
                            данным лицом требование не допускать их распространения без согласия
                            субъекта персональных данных или наличия иного законного основания.</li>

                        <li><strong>Cookies</strong> – технические данные, отправляемые Сайтом Пользователю через
                            сеть интернет, хранящиеся на устройстве Пользователя, которое использовано
                            для просмотра Сайта и передаваемые на Сайт с устройства Пользователя,
                            использованного для просмотра Сайта, при каждом посещении Сайта.</li>

                        <li><strong>IP-адрес</strong> – технический адрес Пользователя в сети интернет.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P1Definitions;