import React, {Component} from 'react';
import SocialMediaItem from "./SocialMediaItem";

class SubFooter extends Component {
    render() {
        return (
            <div className="sub-footer dark-subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-6 col-sm-12">
                            <p className={"copyright"}>
                                <i className="fa fa-copyright" aria-hidden="true"></i> 2020
                                Лаборатория ОмегаЛаб. Вся информация представленная на сайте не
                                является публичной офертой.
                            </p>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-12 footer-social social-media">
                            <ul>
                                <SocialMediaItem link={"https://vk.com/omegaed"} icon={"vk"} />
                                <SocialMediaItem link={"https://facebook.com/omegaed.spb/"} icon={"facebook"} />
                                <SocialMediaItem link={"https://ok.ru/group/57147114586139"} icon={"odnoklassniki"} />
                                <SocialMediaItem link={"https://instagram.com/omega.ed/"} icon={"instagram"} />
                                <SocialMediaItem link={"https://youtube.com/channel/UCZ8HruI5InkN1lnokxB8eZw"} icon={"youtube"} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SubFooter