import React, {Component} from "react";

class Downloads extends Component {
    render() {
        return (
            <section>
                <div className={"row downloads light-list"} id={"software-downloads"}>
                    <div className={"col-lg-4 col-md-4 item"}>
                        <div className="logo-block">
                            <img src={`/assets/images/MenuPages/Software/windows.png`}
                                 alt="Windows"/>
                            <div>для Windows</div>
                        </div>
                        <a href={`/assets/files/software/OmegaBot_IDE-1.0.1.3.Windows.zip`}
                           className={"download-button"}>
                            <button type="button"
                                    className="btn btn-solid">скачать
                            </button>
                        </a>
                    </div>
                    <div className={"col-lg-4 col-md-4 item"}>
                        <div className="logo-block">
                            <img src={`/assets/images/MenuPages/Software/linux.png`}
                                 alt="Windows"/>
                            <div>для Linux</div>
                        </div>
                        <a href={`/assets/files/software/OmegaBot_IDE-1.0.1.3.Linux.tar.gz`}
                           className={"download-button"}>
                            <button type="button"
                                    className="btn btn-solid">скачать
                            </button>
                        </a>
                    </div>
                    <div className={"col-lg-4 col-md-4 item"}>
                        <div className="logo-block">
                            <img src={`/assets/images/MenuPages/Software/macos.png`}
                                 alt="MacOS"/>
                            <div>для MacOS</div>
                        </div>
                        <div className={"download-button"}>
                            <div className={"coming-soon-button"}>coming soon</div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Downloads;