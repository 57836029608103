import React, {Component} from 'react';
import { Accordion, AccordionItem } from 'react-light-accordion';

class BaseTheory extends Component {
    render() {
        return (
            <div className="accordion-container">
                <Accordion atomic={true}>
                    <AccordionItem title="Роль робототехники в образовательном процессе">

                    </AccordionItem>
                    <AccordionItem title="Робототехническая платформа Омегабот, как средство обучения">

                    </AccordionItem>
                    <AccordionItem title="Система управления роботом - аппаратная часть">

                    </AccordionItem>
                    <AccordionItem title="Система управления роботом - программная часть «Знакомство с ArduinoIDE и Omegabot_IDE»">

                    </AccordionItem>
                    <AccordionItem title="Система исполнения робота (приводы, промежуточные передачи, рабочие органы)">

                    </AccordionItem>
                    <AccordionItem title="Система обратной связи робота (генераторные датчики, параметрические датчики)">

                    </AccordionItem>
                    <AccordionItem title="Обзор соревнований и подготовка робота к ним">

                    </AccordionItem>
                </Accordion>
            </div>
        )
    }
}

export default BaseTheory