import React, {Component} from 'react';
import {findFileIcon, shortenString} from "../../../../assets/js/functions";
import {SERVER_HOST} from "../../../../constants/axiosURLs";

class AdvancedFiles extends Component {
    render() {
        let files = this.props.files || [] ;
        return (
            <div className={"product-files product-right"}>
                <h6 className="product-title">Дополнительные файлы</h6>
                <div className={"items row"}>
                    {
                        files.map((file) =>
                            <div className={"preview col col-md-6 col-12"} key={file.id}>
                                <div className={"file-name"} onClick={() => window.open(`${SERVER_HOST}${file.url}`)}>
                                    <img src={findFileIcon(file.type)}
                                         className={"file-icon img-fluid"}
                                         alt={file.name}/>
                                    <span title={file.name}>{shortenString(file.name, 25)}</span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default AdvancedFiles