import React, {Component} from 'react';

class Requisites extends Component {
    render() {
        return (
            <div className={"requisites"}>
                <div className={"company"}>
                    <img src={"/assets/images/MenuPages/Contacts/omegaLogo.svg"} alt={"ООО Омега"}/>
                    Общество с ограниченной ответственностью «Омега»:<br/>
                    ИНН 7826108963 КПП 784201001<br/>
                    Адрес: 191124, Санкт-Петербург, Синопская наб, д. 52 А
                </div>

                <div className={"details"}>
                    <div className={"header"}>
                        <i className="fa fa-university"/> Банковские реквизиты:
                    </div>
                    <div className={"content"}>
                        Петербургский Филиал АО Юникредит Банк<br/>
                        р/с 40702810000024893115<br/>
                        кор/счет 30101810800000000858<br/>
                        БИК 044030858
                    </div>
                </div>

                <div className={"details"}>
                    <div className={"header"}>
                        <i className="fa fa-cog"/> Производство:
                    </div>
                    <div className={"content"}>
                        СПб, Южное шоссе, дом 37/2
                    </div>
                </div>
            </div>
        )
    }
}

export default Requisites