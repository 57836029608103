import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P1Definitions extends Component {
    render() {
        return (
            <AccordionItem title={<li>Термины и определения, используемые в настоящем пользовательском соглашении</li>}>
                <div>
                    Перечисленные ниже термины в рамках настоящего Пользовательского соглашения имеют следующее значение:

                    <ol>
                        <li><strong>Сайт</strong> – совокупность всей информации (текстовой и графической) и онлайн сервисов,  находящиеся
                            в сети интернет по адресу <a href={"/"}>https://omegabot.ru</a>.</li>

                        <li><strong>Администрация Сайта</strong> – сотрудники ООО «Омега», уполномоченные на изменение информации на
                            Сайте.</li>

                        <li><strong>Оператор Сайта</strong> - Общество с ограниченной ответственностью «Омега» (ИНН 7826108963, ОГРН
                            1027810320240), управляющее Сайтом и оказывающее услуги Пользователям Сайта.</li>

                        <li><strong>Посетитель Сайта</strong> -  лицо, пришедшее на Сайт без цели размещения заказа.</li>

                        <li><strong>Пользователь Сайта (далее – Пользователь)</strong> – физическое или юридическое лицо, индивидуальный
                            предприниматель, принимающий условия настоящего Пользовательского соглашения и заключившее
                            с Оператором Сайта настоящее Пользовательское соглашение путем  акцепта  оферты.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P1Definitions;