export const SERVER_HOST = "https://admin.omegabot.ru";
export const PRODUCTS = `${SERVER_HOST}/api/product`;
export const PRODUCT_DETAIL = (id) => `${SERVER_HOST}/api/product/${id}`;

export const CATEGORIES = `${SERVER_HOST}/api/category`;

export const ORDERS = `${SERVER_HOST}/api/order`;
export const ORDER_DETAIL = (id) => `${SERVER_HOST}/api/order/${id}`;

export const MAIL = `${SERVER_HOST}/api/mail`;
export const ATTACHMENT = `${SERVER_HOST}/api/attachment`;
