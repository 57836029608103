import React, {Component} from "react";

class Requirements extends Component {
    render() {
        return (
            <section className={"section-b-space requirements"}>
                <h3>Требования к системе</h3>
                <div className={"list"}>
                    <ul>
                        <li>
                            Операционная система:
                            <ul>
                                <li>Microsoft Windows XP и выше (32-bit или 64-bit).</li>
                            </ul>
                        </li>
                        <li>RAM: 2 ГБ.</li>
                        <li>Пространство на диске: 256 МБ</li>
                        <li>Процессор: минимальное требование - Pentium 2 266 МГц</li>
                    </ul>
                </div>
            </section>
        );
    }
}

export default Requirements;