import React, {Component} from 'react';

export function getRobokassaHref(data) {
    return "https://auth.robokassa.ru/Merchant/Index.aspx?" +
        "MerchantLogin=omegabot" +
        "&InvId=" + data.orderId +
        "&IsTest=" + 0 +
        "&Culture=ru" +
        "&Encoding=utf-8" +
        "&OutSum=" + data.fullCost +
        "&SignatureValue=" + data.sign;
}

export function getOrderData(data) {
    return {
        "delivery": {
            "cost": data.delivery_cost,
            "city": data.delivery_city,
            "address": data.delivery_address,
            "type": data.delivery_type,
            "term": data.delivery_time
        },
        "user": {
            "name": data.full_name,
            "email": data.email,
            "phone": data.phone
        },
        "comment": data.comment,
        "cost": data.fullCost,
        "orderItems": data.cartItems.map( item => ({
            product: item.id,
            count: item.qty
        }) ),

        "isTest": false
    }
}

export function findFileIcon(file) {
    const fileType = file.toLowerCase().split('.')
    const numType = fileType.length - 1
    const type = fileType[numType]

    let path = `${process.env.PUBLIC_URL}/assets/images/common/fileExtension/`
    switch (type) {
        case 'jpg' || 'jpeg':
            return `${path}jpeg.png`
        case 'png':
            return `${path}png.png`
        case 'ino':
            return `${path}ino.png`
        case 'gif':
            return `${path}gif.png`
        case 'pdf':
            return `${path}pdf.png`
        default:
            return `${path}unknown.png`
    }
}

export function getSize(size) {
    let kb = size / 1024
    let mb = kb / 1024
    if (kb < 100) {
        return `${kb.toFixed(1)} КБ`
    } else {
        return `${mb.toFixed(1)} МБ`
    }
}

export function declOfNum(n, text_forms) {
    n = Math.abs(n) % 100; let n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 === 1) { return text_forms[0]; }
    return text_forms[2];
}

export function shortenString(string, limit) {
    let [ name, extension ] = string.split(".");

    name = name.length > limit ?
        ( name.slice(0, limit - 2) + "..." ) :
        name;

    return name + (extension ? `.${extension}` : '');
}

export function findObject(array, prop, value) {
    let obj = {};

    array.forEach(item => {
        if (item[prop] === value) {
            obj = item;
        }
    });

    return obj;
}

export function readGetParameters() {
    let params = document.location.search
        .replace('?', '').replace(/\+/g, "%20", "g")
        .split('&')
        .reduce(
            function (p, e) {
                let a = e.split('=');
                p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                return p;
            },
            {}
        );
    delete params[""];
    return params;
}

export const getCartTotal = cartItems => {
    var total = 0;
    for(var i=0; i<cartItems.length; i++){
        total += parseInt(cartItems[i].qty, 10)*parseInt((cartItems[i].price * (1 - cartItems[i].discount/100)), 10);
    }
    return total;
}

/* Function for collapsing footer menu in mobile version */
export const SlideUpDown = function(classnames) {
    var accords = document.querySelectorAll("."+classnames+" h4");
    [].forEach.call(accords, function(elemt) {
        elemt.innerHTML += '<span class="according-menu"></span>';
    })

    var anchors = document.getElementsByClassName(classnames);
    for(var i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];
        anchor.onclick = function() {
            var elems = document.querySelectorAll("."+classnames);
            [].forEach.call(elems, function(elemt) {
                elemt.classList.remove("active");
                var el = elemt.nextElementSibling;
                el.style.height = el.offsetHeight + 'px'
                el.style.transitionProperty = `height, margin, padding`
                el.style.transitionDuration = '500ms'
                el.offsetHeight // eslint-disable-line no-unused-expressions
                el.style.overflow = 'hidden'
                el.style.height = 0
                el.style.paddingTop = 0
                el.style.paddingBottom = 0
                el.style.marginTop = 0
                el.style.marginBottom = 0
                el.style.display = 'none'
                el.style.removeProperty('height')
                el.style.removeProperty('padding-top')
                el.style.removeProperty('padding-bottom')
                el.style.removeProperty('margin-top')
                el.style.removeProperty('margin-bottom')
                el.style.removeProperty('overflow')
                el.style.removeProperty('transition-duration')
                el.style.removeProperty('transition-property')

            });

            this.classList.add('active');
            var element = this.nextElementSibling;
            element.style.removeProperty('display')
            let display = window.getComputedStyle(element).display

            if(element.classList.contains('show')) {
                element.classList.remove('show');

                this.classList.remove("active");
                element.style.height = element.offsetHeight + 'px'
                element.style.transitionProperty = `height, margin, padding`
                element.style.transitionDuration = '500ms'
                element.offsetHeight // eslint-disable-line no-unused-expressions
                element.style.overflow = 'hidden'
                element.style.height = 0
                element.style.paddingTop = 0
                element.style.paddingBottom = 0
                element.style.marginTop = 0
                element.style.marginBottom = 0
                element.style.display = 'none'
                element.style.transitionProperty = `height, margin, padding`
                element.style.transitionDuration = '500ms'
                element.style.removeProperty('height')
                element.style.removeProperty('padding-top')
                element.style.removeProperty('padding-bottom')
                element.style.removeProperty('margin-top')
                element.style.removeProperty('margin-bottom')
                window.setTimeout(function () {
                    element.style.removeProperty('overflow')
                    element.style.removeProperty('transition-duration')
                    element.style.removeProperty('transition-property')
                }, 500)

            }else{
                element.classList.add('show');
                if (display === 'none') display = 'block'
                element.style.display = display
                let height = element.offsetHeight
                element.style.overflow = 'hidden'
                element.style.height = 0
                element.style.paddingTop = 0
                element.style.paddingBottom = 0
                element.style.marginTop = 0
                element.style.marginBottom = 0
                element.offsetHeight // eslint-disable-line no-unused-expressions
                element.style.transitionProperty = `height, margin, padding`
                element.style.transitionDuration = '500ms'
                element.style.height = height + 'px'
                element.style.removeProperty('padding-top')
                element.style.removeProperty('padding-bottom')
                element.style.removeProperty('margin-top')
                element.style.removeProperty('margin-bottom')
                window.setTimeout(function () {
                    element.style.removeProperty('height')
                    element.style.removeProperty('overflow')
                    element.style.removeProperty('transition-duration')
                    element.style.removeProperty('transition-property')
                }, 500)
            }
        }
    }

    var elems = document.querySelectorAll("."+classnames);
    [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: none";
    });

}