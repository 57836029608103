import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P5DataProcessing extends Component {
    render() {
        return (
            <AccordionItem title={<li>Способы и сроки обработки персональной информации</li>}>
                <div>

                    <ol>
                        <li>Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным
                            способом, в том числе в информационных системах персональных данных с использованием средств
                            автоматизации или без использования таких средств.</li>

                        <li>ООО «Омега» вправе передать персональную информацию Пользователя третьим лицам в следующих
                            случаях:
                            <ol>
                                <li>Пользователь выразил согласие на такие действия.</li>

                                <li>Пользователь соглашается с тем, что ООО «Омега» вправе передавать персональные данные
                                    третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам
                                    электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на Сайте.</li>

                                <li>Передача предусмотрена российским или иным применимым законодательством в рамках
                                    установленной законодательством процедуры.</li>
                            </ol>
                        </li>
                        <li>Персональные данные Пользователя могут быть переданы уполномоченным органам государственной
                            власти Российской Федерации только по основаниям и в порядке, установленным законодательством
                            Российской Федерации.</li>

                        <li>При утрате или разглашении персональных данных ООО «Омега» информирует Пользователя об
                            утрате или разглашении персональных данных.</li>

                        <li>ООО «Омега» принимает необходимые организационные и технические меры для защиты персональной
                            информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения,
                            блокирования, копирования, распространения, а также от иных неправомерных действий третьих
                            лиц.</li>

                        <li>ООО «Омега» совместно с Пользователем принимает все необходимые меры по предотвращению убытков
                            или иных отрицательных последствий, вызванных утратой или разглашением персональных данных
                            Пользователя.</li>

                        <li>В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме
                            случаев добровольного предоставления Пользователем информации о себе для общего доступа
                            неограниченному кругу лиц. При использовании отдельных сервисов Пользователь соглашается с тем,
                            что определенная часть его персональной информации становится общедоступной.</li>

                        <li>Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным
                            способом, в том числе в информационных системах персональных данных с использованием средств
                            автоматизации или без использования таких средств. Обработка персональных данных Пользователей
                            осуществляется в соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных
                            данных».</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P5DataProcessing;